import { useEffect, useState } from "react";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import i18n from "../../common/languageConf";
import { Helmet } from "react-helmet-async";

function PowerMeterPage() {

	useEffect(() => {
		window.scrollTo(0, 0);

	}, [GlobalSettingStore.GlobalSetting])

	return (
		<>
			<Helmet>
				<title>EPC PROJE | {i18n.t("powermetertextbaslik")}</title>
				<meta name="description" content={i18n.t("powermetermetaaciklama").slice(0, 160)} />
			</Helmet>
			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>{i18n.t("powermetertextbaslik")}</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>

							<li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}> {i18n.t("HOME")}</Link></li>
							<li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
							<li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies/compere"}>{i18n.t("comparebyk")}</Link></li>
							<li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
							<li><span>{i18n.t("powermetertextbaslik")}</span></li>
						</ul>
					</div>
				</div>
			</section>
			<div className="container">
				<div className="row">

					<div className="col-12">
						<div className="img-box" style={{ display: "flex", justifyContent: "center" }}>
							<div className="first" style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "75%", height: "90%", }} src={`${process.env.PUBLIC_URL}/img/powermeterlast.jpg`} alt="image" /> </div>
							<div className="second" style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "75%", height: "90%", }} src={`${process.env.PUBLIC_URL}/img/powermeter3.png`} alt="image" /> </div>
						</div>
						<div className="text" style={{ whiteSpace: "pre-line" }}>
							<h3>{i18n.translate("powermetertextbaslik")}</h3>
							{i18n.translate("powermetertexticerik")}
							<h4>{i18n.translate("powermetertextbaslik2")}</h4>
							{i18n.translate("powermetertexticerik2")}
							<h4>{i18n.translate("powermetertextbaslik3")}</h4>
							{i18n.translate("powermetertexticerik3")}
						</div>
						<br />
						<div>
							<iframe src="/pdfjs/web/viewer.html?file=/pdf/compere/TaEnergy.pdf"
								width="100%"
								height="700"
								frameBorder="0"
								scrolling="no">

							</iframe>

						</div>

						<div className="text" style={{ whiteSpace: "pre-line" }}>
							<h3>{i18n.translate("powermetertextbaslik4")}</h3>
							{i18n.translate("powermetertexticerik4")}
							<h4>{i18n.translate("powermetertextbaslik5")}</h4>
							{i18n.translate("powermetertexticerik5")}
							<h4>{i18n.translate("powermetertextbaslik6")}</h4>
							{i18n.translate("powermetertexticerik6")}
						</div>
						{/* <div className="text" style={{ whiteSpace: "pre-line" }}>
							{i18n.translate("powermetertexticerik4")}
						</div> */}
						<div className="img-box" style={{ display: "flex", justifyContent: "center" }}>
							<div className="first" style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "75%", height: "90%", }} src={`${process.env.PUBLIC_URL}/img/powermeterlas1.jpg`} alt="image" /> </div>
							<div className="first" style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "75%", height: "90%", }} src={`${process.env.PUBLIC_URL}/img/powermeterlas2.jpg`} alt="image" /> </div>
						</div>
						<div>
							<iframe src="/pdfjs/web/viewer.html?file=/pdf/compere/KPM312Threephasemulticannelpowermeterinstruction.pdf"
								width="100%"
								height="700"
								frameBorder="0"
								scrolling="no">
							</iframe>
						</div>
						<br /><br /><br />
						<div className="questions-sol">

						</div>

					</div>

				</div>
			</div>
		</>
	);
}

const PowerMeterPageComponent = observer(PowerMeterPage);
export default PowerMeterPageComponent;
