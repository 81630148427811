import { useEffect } from "react";
import i18n from "../../common/languageConf";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

function Compere() {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])
    return (
        <>

            <section className="header-title">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2>{i18n.t("compere")}</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-nav">
                <div className="container">
                    <div className="row">
                        <ul>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/"}>{i18n.t("HOME")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><Link style={{ fontWeight: "bold", fontSize: "14px" }} to={"/companies"}>{i18n.t("temsilcilikler")}</Link></li>
                            <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li><span>{i18n.t("compere")}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="projects-section">
                <div className="container">
                    <div className="row">
                        <div className="thm-container">
                            <div className="col-md-4 mix item enerji">
                                <Link to="/companies/compere/powermeter">
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image="../../../img/powermeterlast.jpg"
                                                alt="Zhanggu Logo"
                                                style={{ objectFit: "cover", width: "100%", }}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                {i18n.t("powermetertextbaslik")}
                                                </Typography>
                                                <Typography style={{ fontSize: 14 }} variant="body2" color="text.secondary">
                                                {i18n.t("comperecompany")}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </div>
                            

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

const ComperePage = observer(Compere);
export default ComperePage;
